body,
html,
#root {
  height: 100%;
  background-color: #eee;
}

.CenterContent {
  display: flex;
  min-height: 100vh;
}

.CoreuiCheckBox > .label {
  margin-left: 10px;
}

.DetailField-key {
  padding-right: 10px;
}

.ReactSelectHelper .is-invalid ~ .invalid-feedback,
.ReactSelectHelper .is-invalid ~ .invalid-tooltip {
  display: block;
}

.ReactSelectHelper .is-invalid .AsynSelect__control,
.ReactSelectHelper .is-invalid .ReactSelect__control {
  border-color: #f86c6b;
}

.AuthLayout a {
  display: block;
}

/*# sourceMappingURL=releox.css.map */
